<template>
  <ul class="decorative-list">
    <slot />
  </ul>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.decorative-list {
  list-style: none;
  padding-left: 0;

  li {
    background: url('../../assets/images/Global/bolt.png') no-repeat;
    min-height: 2.1rem;
    padding-left: 2.3rem;
    color: $c-text;
  }
}
</style>
